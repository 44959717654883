/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/
import { Quaternion } from '@babylonjs/core/Maths/math.vector';
/**
 * Get the transform object from a node
 * @param node the node to get the transform from
 * @returns the transform
 */
export function getTransformFromNode(node) {
    const quaternion = Quaternion.FromEulerVector(node.rotation);
    return {
        matrix: [...node.getWorldMatrix().asArray()],
        trs: {
            translation: node.position.asArray(),
            quaternion: quaternion.asArray(),
            eulerAngles: quaternion.toEulerAngles().asArray(),
            scaling: node.scaling.asArray(),
        },
    };
}
