/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { A3dResolver, ViewerEvents } from '@a3d-viewer/core';
import { LitElement, css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { createRef, ref } from 'lit/directives/ref.js';
import _uniqueId from 'lodash/uniqueId';
import _debounce from 'lodash/debounce';
import { A3dRenderEngine } from '@a3d-viewer/babylon-renderer';
import '@a3d-viewer/renderer-types';
/**
 * Core component of the viewer. It is responsible for rendering the 3D scene and managing the lifecycle of the viewer.
 * @element a3d-viewer
 */
let A3dViewer = class A3dViewer extends LitElement {
    constructor() {
        super();
        this.debug = false;
        this.edit = false;
        this.internalId = _uniqueId('root-');
        this.canvasRef = createRef();
        /** @internal */
        this.showLoadingScreen = false;
        this.elements = {};
        this.loaded = false;
        /** @internal */
        this.canvasResizeObserver = new ResizeObserver((entries) => {
            for (const entry of entries) {
                if (entry.target instanceof HTMLCanvasElement) {
                    if (Math.round(entry.target.width) !==
                        Math.round(entry.contentRect.width) ||
                        Math.round(entry.target.height) !==
                            Math.round(entry.contentRect.height)) {
                        // called when resize don't come from window resize event
                        // we have to call a resize with render to avoid flickering on Firefox
                        this.resolver?.resizeWithRender();
                    }
                }
            }
        });
        this.resize = () => {
            this.resolver?.resize();
        };
    }
    get infos() {
        if (!this.renderer) {
            console.warn('Viewer not initialized');
            return {};
        }
        return this.resolver?.state;
    }
    disposeElement(id) {
        this.resolver?.disposeElement?.(id);
    }
    /** @internal */
    registerChild(child, group) {
        if (child)
            this.addElement(child, group);
    }
    /** @internal */
    addElement(child, group) {
        const existingGroup = this.elements[group];
        this.elements = {
            ...this.elements,
            [group]: {
                ...existingGroup,
                [child.id]: { ...child },
            },
        };
    }
    /** @internal */
    unregister(id) {
        delete this.elements[id];
        this.elements = { ...this.elements };
        this.requestUpdate();
    }
    connectedCallback() {
        super.connectedCallback();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        if (this.canvasRef.value)
            this.canvasResizeObserver.unobserve(this.canvasRef.value);
        if (window)
            window.removeEventListener('resize', this.resize);
        this.removeEventListener(ViewerEvents.INITIALIZING, this.onSceneLoading);
        this.removeEventListener(ViewerEvents.UPDATE, this.onSceneLoading);
        this.removeEventListener(ViewerEvents.READY, this.onSceneReady);
    }
    viewerSupported(canvas) {
        try {
            if (!HTMLCanvasElement.prototype.getContext || !canvas.getContext) {
                console.debug('Your browser does not support canvas');
                return false;
            }
            const gl = canvas.getContext('webgl2') || canvas.getContext('webgl');
            return gl instanceof WebGLRenderingContext || WebGL2RenderingContext;
        }
        catch (e) {
            return false;
        }
    }
    firstUpdated(_changedProperties) {
        if (this.canvasRef.value instanceof HTMLCanvasElement &&
            this.viewerSupported(this.canvasRef.value)) {
            this.renderer = new A3dRenderEngine(this.canvasRef.value);
            this.resolver = new A3dResolver(this, this.renderer);
            this.runDebounce = _debounce(() => {
                this.resolver?.run(this.elements);
            }, 300);
            this.addEventListener(ViewerEvents.INITIALIZING, this.onSceneLoading);
            this.addEventListener(ViewerEvents.UPDATE, this.onSceneLoading);
            this.addEventListener(ViewerEvents.READY, this.onSceneReady);
            this.addEventListener(ViewerEvents.READY, () => {
                this.loaded = true;
            }, { once: true });
            this.addEventListener('error', this.onError);
        }
        if (this.canvasRef.value)
            this.canvasResizeObserver.observe(this.canvasRef.value);
    }
    updated(_changedProperties) {
        super.updated(_changedProperties);
        // avoid rerendering when only showLoadingScreen changes
        if (_changedProperties.has('showLoadingScreen') &&
            _changedProperties.size === 1) {
            return;
        }
        if (this.elements.Scenes) {
            if (this.loaded) {
                this.resolver?.run(this.elements);
            }
            else {
                this.runDebounce();
            }
        }
    }
    onSceneLoading() {
        this.showLoadingUI();
    }
    onSceneReady() {
        this.hideLoadingUI();
    }
    onError() {
        this.hideLoadingUI();
    }
    showLoadingUI() {
        this.showLoadingScreen = true;
    }
    hideLoadingUI() {
        this.showLoadingScreen = false;
    }
    /**
     * Check if the scene is ready
     * @returns boolean
     */
    isSceneReady() {
        return this.infos?.scene?.isReady;
    }
    render() {
        return html `<div>
      <canvas ${ref(this.canvasRef)}></canvas>
      <slot></slot>
      <slot name="toolbar"></slot>
      <slot
        name="loadingScreen"
        class="${this.showLoadingScreen ? 'visible' : 'hidden'}"
      ></slot>
      <slot name="ar"></slot>
      <slot name="info"></slot>
    </div>`;
    }
};
A3dViewer.styles = css `
    :host {
      display: flex;
      align-items: stretch;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

    :host > div {
      position: relative;
      display: flex;
      width: 100%;
      align-items: stretch;
    }

    :host > div > canvas {
      width: 100%;
      flex: 1;
      display: block;
      border: none !important;
      outline: none !important;
    }

    :host(.cursor-crosshair) > div > canvas {
      cursor: crosshair;
    }

    slot[name='loadingScreen'] {
      display: none;
      opacity: 0;
      z-index: 1;
    }

    slot[name='loadingScreen'].visible {
      display: block;
      opacity: 1;
    }
    slot[name='loadingScreen'].hidden {
      animation: hideLoadingUI 1s ease-out forwards;
    }

    @keyframes hideLoadingUI {
      0% {
        opacity: 1;
      }
      99% {
        opacity: 0;
      }
      100% {
        display: none;
      }
    }
  `;
__decorate([
    property({ type: Boolean })
], A3dViewer.prototype, "debug", void 0);
__decorate([
    property({ type: Boolean })
], A3dViewer.prototype, "edit", void 0);
__decorate([
    state()
], A3dViewer.prototype, "showLoadingScreen", void 0);
__decorate([
    state()
], A3dViewer.prototype, "elements", void 0);
A3dViewer = __decorate([
    customElement('a3d-viewer')
], A3dViewer);
export { A3dViewer };
