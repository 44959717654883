/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/
import { MeshInputAnimationStatus, RendererEvents, } from '@a3d-viewer/renderer-types';
import { PointerEventTypes } from '@babylonjs/core';
import _debounce from 'lodash/debounce';
const CAMERA_STOPPED_DEBOUNCE_TIME = 10000;
/**
 * Check if the camera is being moved
 * Compare the 2 positions of the camera
 */
export function isCameraBeingMoved(currentPosition, lastPosition) {
    return !currentPosition.equals(lastPosition);
}
export function createCameraRenderObservable(camera) {
    let lastPosition = camera.position.clone();
    let isCameraMoved = false;
    return (cameraPostion, cameraMoved, cameraStopped) => {
        if (isCameraBeingMoved(cameraPostion, lastPosition)) {
            console.debug('camera moved');
            cameraMoved();
            isCameraMoved = true;
        }
        else if (isCameraMoved) {
            console.debug('camera not moving');
            cameraStopped();
            isCameraMoved = false;
        }
        lastPosition = cameraPostion.clone();
    };
}
export function hasAnyAnimationPlaying(scene) {
    // Check all meshes to see if any animation is playing
    const meshes = scene.meshes;
    let hasAnyAnimationPlaying = false;
    meshes.forEach((mesh) => {
        if (mesh.metadata &&
            mesh.metadata.animationStatus === MeshInputAnimationStatus.PLAY) {
            hasAnyAnimationPlaying = true;
        }
    });
    console.debug('hasAnyAnimationPlaying', hasAnyAnimationPlaying);
    return hasAnyAnimationPlaying;
}
/**
 * Manage the render loop by spying on the camera position to detect if the view is changing.
 * Return an update function that has to be called when elements on the scene change.
 * (Has to be called manually to avoid subscribing to several Observables)
 */
export function manageRenderLoop(canvas, scene, renderer) {
    let loopOn = false;
    const startRenderLoop = () => {
        if (loopOn) {
            return;
        }
        loopOn = true;
        console.debug('start render loop');
        scene.getEngine().runRenderLoop(() => {
            scene?.render();
        });
    };
    const stopRenderLoopDebounced = _debounce(() => {
        if (hasAnyAnimationPlaying(scene)) {
            return;
        }
        loopOn = false;
        console.debug('stop render loop');
        scene.getEngine().stopRenderLoop();
    }, CAMERA_STOPPED_DEBOUNCE_TIME);
    const update = () => {
        console.debug('update render loop');
        startRenderLoop();
        stopRenderLoopDebounced();
    };
    const cameraMoved = () => {
        console.debug('camera moved');
        renderer.cameraUpdated();
        startRenderLoop();
    };
    const cameraStopped = () => {
        stopRenderLoopDebounced();
    };
    canvas.addEventListener(RendererEvents.DEFAULT_CAMERA_CREATED, () => {
        const camera = scene.activeCamera;
        if (!camera) {
            return;
        }
        const cameraRenderObservable = createCameraRenderObservable(camera);
        // The renderloop will only being stopped :
        // - after the camera has not moved
        scene.onAfterCameraRenderObservable.add(() => {
            // Create the camera render observable
            cameraRenderObservable(camera.position, () => { }, cameraStopped);
        });
        // Used to detect if the camera need to move
        scene.onPointerObservable.add((eventData) => {
            const pointerEvent = eventData.event;
            // If the user is pressing the mouse, consider the camera is moving
            if (eventData.type == PointerEventTypes.POINTERWHEEL ||
                (pointerEvent.pressure > 0 &&
                    eventData.type == PointerEventTypes.POINTERMOVE)) {
                cameraMoved();
            }
            else if (eventData.type == PointerEventTypes.POINTERDOWN) {
                cameraMoved();
            }
        });
        // Used to detect if the camera need to move
        scene.onKeyboardObservable.add(() => {
            cameraMoved();
        });
    }, { once: true });
    return update;
}
