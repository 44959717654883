/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/
import { POST_EFFECTS, PostEffect } from '@a3d-viewer/renderer-types';
import { Mesh } from '@babylonjs/core/Meshes/mesh';
import { MeshBuilder } from '@babylonjs/core/Meshes/meshBuilder';
import { IblShadowsRenderPipeline } from '@babylonjs/core/Rendering/IBLShadows/iblShadowsRenderPipeline';
import { ShaderMaterial } from '@babylonjs/core/Materials/shaderMaterial';
import { Constants } from '@babylonjs/core/Engines/constants';
import { Vector2 } from '@babylonjs/core/Maths/math.vector';
import { isUserMesh } from '../resources';
const RESOLUTION_EXP = 7;
const SAMPLE_DIRECTIONS = 3;
const DECREASED_RENDER_FACTOR = 0.25;
const RENDER_SIZE_FACTOR = 1;
class ShadowsIblPost extends PostEffect {
    constructor(scene) {
        super();
        this.type = POST_EFFECTS.SHADOWS_IBL;
        this.scene = scene;
        this.iblPipeline = new IblShadowsRenderPipeline('iblShadowsPipeline', scene, {
            resolutionExp: RESOLUTION_EXP,
            sampleDirections: SAMPLE_DIRECTIONS,
            ssShadowsEnabled: true,
            shadowRemanence: 0.7,
            triPlanarVoxelization: true,
        }, [this.scene.activeCamera]);
        this.iblPipeline.allowDebugPasses = false;
        this.iblPipeline.gbufferDebugEnabled = false;
        this.iblPipeline.cdfDebugEnabled = false;
        this.iblPipeline.voxelDebugEnabled = false;
        this.iblPipeline.accumulationPassDebugEnabled = false;
        this.shadowGround = MeshBuilder.CreatePlane('ground', { size: 1 }, this.scene);
        // Custom ground material that just catches the IBL shadows
        var customGroundVertexShader = `
        precision highp float;

        // Attributes
        attribute vec3 position;
        attribute vec2 uv;

        // Uniforms
        uniform mat4 worldViewProjection;

        // Varying
        varying vec2 vUV;

        void main(void) {
            gl_Position = worldViewProjection * vec4(position, 1.0);
            vUV = uv;
        }
    `;
        var customGroundFragmentShader = `
        precision highp float;

        // Sampler
        uniform sampler2D shadowTexture;
        uniform vec2 renderTargetSize;
        uniform float shadowOpacity;
        // Varying
        varying vec2 vUV;

        void main(void) {
            float uvBasedOpacity = clamp(length(vUV * vec2(2.0) - vec2(1.0)), 0.0, 1.0);
            uvBasedOpacity = uvBasedOpacity * uvBasedOpacity;
            uvBasedOpacity = 1.0 - uvBasedOpacity;
            // float uvBasedOpacity = length(vUV - vec2(0.5, 0.5))
            vec2 screenUv = gl_FragCoord.xy / renderTargetSize;
            vec3 shadowValue = texture2D(shadowTexture, screenUv).rrr;
            float totalOpacity = shadowOpacity * uvBasedOpacity;
            shadowValue = mix(vec3(1.0), shadowValue, totalOpacity);
            gl_FragColor.rgb = shadowValue;
            gl_FragColor.a = 1.0;
        }
    `;
        const customGroundAttributes = {
            attributes: ['position', 'uv'],
            uniforms: [
                'world',
                'worldView',
                'worldViewProjection',
                'view',
                'projection',
                'renderTargetSize',
                'shadowOpacity',
            ],
            samplers: ['shadowTexture'],
        };
        // Create a shader material
        this.groundShadowMaterial = new ShaderMaterial('customGroundMaterial', scene, {
            vertexSource: customGroundVertexShader,
            fragmentSource: customGroundFragmentShader,
        }, customGroundAttributes);
        this.groundShadowMaterial.alphaMode = Constants.ALPHA_MULTIPLY;
        this.groundShadowMaterial.alpha = 0.99;
        this.shadowGround.rotation.x = Math.PI / 2;
        this.shadowGround.material = this.groundShadowMaterial;
        this.iblPipeline.onShadowTextureReadyObservable.addOnce(() => {
            this.groundShadowMaterial.setTexture('shadowTexture', this.iblPipeline._getAccumulatedTexture());
        });
        this.meshUpdated();
        // When the canvas is resized, we need to update the ground shader with the new render target size
        this.scene.getEngine().onResizeObservable.add(() => {
            this._updateGroundProperties();
        });
        this.process();
    }
    suspend() {
        this.iblPipeline.shadowRenderSizeFactor = DECREASED_RENDER_FACTOR;
    }
    process() {
        this.iblPipeline.shadowRenderSizeFactor = RENDER_SIZE_FACTOR;
        this._updateGroundProperties();
    }
    dispose() {
        console.debug('Dispose shadows');
        this.iblPipeline.toggleShadow(false);
        this.iblPipeline.dispose();
        this.scene.removeMesh(this.shadowGround);
    }
    meshUpdated() {
        // Add shadow-casters
        const skybox = this.scene.getMeshByName('skyBox');
        this.scene.meshes.forEach((mesh) => {
            if (mesh instanceof Mesh && isUserMesh(mesh)) {
                this.iblPipeline.addShadowCastingMesh(mesh);
            }
        });
        // Add shadow-receivers
        this.scene.meshes.forEach((mesh) => {
            if (mesh instanceof Mesh && mesh !== skybox && mesh.material) {
                this.iblPipeline.addShadowReceivingMaterial(mesh.material);
            }
        });
        window.iblPipeline = this.iblPipeline;
        this.iblPipeline.updateSceneBounds();
        this.iblPipeline.updateVoxelization();
    }
    _updateGroundProperties() {
        this.shadowGround.material = this.groundShadowMaterial;
        const groundSize = 4.0 * this.iblPipeline.voxelGridSize;
        this.shadowGround.scaling.set(groundSize, groundSize, groundSize);
        this.groundShadowMaterial.setVector2('renderTargetSize', new Vector2(this.scene.getEngine().getRenderWidth(), this.scene.getEngine().getRenderHeight()));
        this.groundShadowMaterial.setFloat('shadowOpacity', this.iblPipeline.shadowOpacity);
        this.groundShadowMaterial.setTexture('shadowTexture', this.iblPipeline._getAccumulatedTexture());
        if (this.scene.activeCamera) {
            this.scene.activeCamera.maxZ = 45 * this.iblPipeline.voxelGridSize;
            this.scene.activeCamera.minZ = 0.1 * this.iblPipeline.voxelGridSize;
        }
    }
    setSkyboxVisible(skyboxVisible) {
        // Depending on the skybox visibility, we need to adjust the ground position.
        // If the skybox is visible, the ground should be slightly above the floor to avoid z-fighting.
        if (skyboxVisible) {
            this.shadowGround.position.y = 0.001 * this.iblPipeline.voxelGridSize;
            // If the skybox is not visible, the ground should be slightly below the floor to avoid potential
            // z-fighting with the model.
        }
        else {
            this.shadowGround.position.y = -0.001 * this.iblPipeline.voxelGridSize;
        }
    }
    setIblTexture() {
        this.iblPipeline.onNewIblReadyObservable.addOnce(() => {
            this.iblPipeline.resetAccumulation();
        });
    }
    set envRotation(rotation) {
        this.iblPipeline.envRotation = rotation;
    }
    resetAccumulation() {
        this.iblPipeline.resetAccumulation();
    }
}
export default ShadowsIblPost;
