/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/
import { CubeTexture } from '@babylonjs/core/Materials/Textures/cubeTexture';
import { HDRCubeTexture } from '@babylonjs/core/Materials/Textures/hdrCubeTexture';
import { Texture } from '@babylonjs/core/Materials/Textures/texture';
import { Mesh } from '@babylonjs/core/Meshes/mesh';
import { MeshBuilder } from '@babylonjs/core/Meshes/meshBuilder';
import { PBRMaterial } from '@babylonjs/core/Materials/PBR/pbrMaterial';
import { BabylonResource } from './BabylonResource';
import { BackgroundMaterial } from '@babylonjs/core/Materials/Background/backgroundMaterial';
import { POST_EFFECTS } from '@a3d-viewer/renderer-types';
class EnvResourceConfig {
    constructor(input) {
        this.shouldDispose = false;
        this.input = input;
    }
    update(input) {
        if (input.src !== this.input.src) {
            this.shouldDispose = true;
        }
        this.input = input;
        setTimeout(() => {
            this.shouldDispose = false;
        }, 0);
    }
    get skyboxVisible() {
        return this.input.visible;
    }
    get microSurface() {
        if (!this.input.blurLevel) {
            return 1.0;
        }
        return 1.0 - this.input.blurLevel;
    }
    get env() {
        return this.input.src;
    }
    get textureRotation() {
        return this.input.rotationY || 0;
    }
    get skyboxRotation() {
        return -this.textureRotation;
    }
    get intensity() {
        return this.input.intensity || 1.0;
    }
}
/**
 * Env resource class for Babylon resolver
 */
class EnvResource extends BabylonResource {
    constructor(id, uuid, resolver) {
        super(id, uuid, resolver);
        this.resolver.envCreate(id);
        // skybox initialisation
        this.skyBox = MeshBuilder.CreateBox('skyBox', { size: 1.0, sideOrientation: Mesh.BACKSIDE }, resolver.scene);
        this.skyBox.isPickable = false;
        const skyMaterial = new BackgroundMaterial('skyBoxMaterial', resolver.scene);
        skyMaterial.enableGroundProjection = true;
        skyMaterial.projectedGroundRadius = 5;
        skyMaterial.projectedGroundHeight = 1;
        skyMaterial.backFaceCulling = false;
        this.skyBox.material = skyMaterial;
    }
    getObjectResource() {
        throw new Error('Method not implemented.');
    }
    async update(input) {
        const scene = this.resolver.scene;
        if (!this.config) {
            this.config = new EnvResourceConfig(input);
        }
        else {
            this.config.update(input);
        }
        if (!scene || !this.config.env) {
            console.error('No src provided for env');
            return;
        }
        const size = this.resolver.sceneDimension();
        const maxSize = Math.max(size[0], Math.max(size[1], size[2]));
        const skyboxSize = 15.0 * maxSize;
        this.skyBox.scaling.set(skyboxSize, skyboxSize, skyboxSize);
        this.skyBox.position.y = skyboxSize / 2 - 0.01 * maxSize;
        this.skyBox.material.projectedGroundRadius =
            12.0 * maxSize;
        this.skyBox.material.projectedGroundHeight =
            2 * maxSize;
        this.skyBox.setEnabled(this.config.skyboxVisible);
        // a3d-environment src change
        if (this.resource && this.config.shouldDispose) {
            if (scene.environmentTexture)
                scene.environmentTexture.dispose();
            this.resource.dispose();
            this.resource = undefined;
        }
        if (!this.resource) {
            this.resource = this.loadSkyboxPathTexture(scene, this.config.env);
            scene.environmentTexture = this.resource;
            if (this.skyBox.material &&
                this.skyBox.material instanceof BackgroundMaterial) {
                this.skyBox.material.reflectionTexture =
                    scene.environmentTexture.clone();
                if (this.skyBox.material.reflectionTexture)
                    this.skyBox.material.reflectionTexture.coordinatesMode =
                        Texture.SKYBOX_MODE;
            }
            if (this.resolver.effects[POST_EFFECTS.SHADOWS_IBL]) {
                ;
                this.resolver.effects[POST_EFFECTS.SHADOWS_IBL].setIblTexture();
            }
            // Make sure we update all the materials in the scene to use the new env texture
            scene.materials.forEach((material) => {
                if (material instanceof PBRMaterial) {
                    material.reflectionTexture = scene.environmentTexture;
                }
            });
        }
        // Blur
        const material = this.skyBox.material;
        material.reflectionBlur = 1.0 - this.config.microSurface;
        // a3d-environment rotationY change
        this.resource.rotationY = this.config.textureRotation;
        if (this.resolver.effects[POST_EFFECTS.SHADOWS_IBL]) {
            ;
            this.resolver.effects[POST_EFFECTS.SHADOWS_IBL].envRotation = this.config.textureRotation;
            this.resolver.effects[POST_EFFECTS.SHADOWS_IBL].setSkyboxVisible(this.config.skyboxVisible);
        }
        // a3d-environment intensity change
        this.resource.level = this.config.intensity;
        if (this.config.skyboxVisible &&
            this.skyBox &&
            this.skyBox.material &&
            this.skyBox.material instanceof BackgroundMaterial &&
            this.skyBox.material.reflectionTexture) {
            this.skyBox.material.reflectionTexture.level = this.config.intensity;
            this.skyBox.material.reflectionTexture.rotationY =
                this.config.textureRotation;
        }
        CubeTexture.WhenAllReady([this.resource], () => {
            this.resolver.envReady(this.state);
        });
        this.resolver.envUpdated(this.state);
    }
    loadSkyboxPathTexture(scene, path) {
        let envTexture;
        if (path.indexOf('.env') === path.length - 4) {
            envTexture = CubeTexture.CreateFromPrefilteredData(path, scene);
        }
        else {
            envTexture = new HDRCubeTexture(path, scene, 256, false, true, false, true);
        }
        return envTexture;
    }
    dispose() {
        if (!this.resource) {
            return;
        }
        console.debug('dispose environment');
        const scene = this.resolver.scene;
        if (scene && this.resource) {
            scene.environmentTexture = null;
            this.resource.dispose();
        }
        if (this.skyBox) {
            if (this.skyBox.material)
                this.skyBox.material.dispose();
            this.skyBox.material = null;
            this.skyBox.dispose();
        }
        this.resolver.envDisposed(this.id);
    }
    setRelation(_parent) {
        console.warn('Setting parent on an env has no effect');
    }
    get state() {
        if (!this.resource) {
            return {};
        }
        return {
            src: this.resource.url,
            rotationY: this.resource.rotationY * (180 / Math.PI),
            intensity: this.resource.level,
            blurLevel: 1.0 - this.skyBox.material.reflectionBlur,
            visible: this.skyBox.isEnabled(),
        };
    }
}
export { EnvResourceConfig, EnvResource };
